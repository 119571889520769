import React from "react";
import { NavLink } from "react-router-dom";

class Sidebar extends React.Component {
  render() {
    return (
      <aside class="main-sidebar sidebar-light-primary elevation-4 sidebar-no-expand">
        {/* Main Sidebar Container */}
        {/* Brand Logo */}
        <a href="" class="brand-link text-center">
          <span class="brand-text font-weight-bold">会員マイページ</span>
        </a>

        {/* Sidebar */}
        <div class="sidebar">
          {/* Sidebar Menu */}
          <nav class="mt-2">
            <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library */}
              <li class="nav-item">
                <NavLink to="/" className="nav-link">
                  <i className="nav-icon fas fa-rss"></i>
                  <p>トップページ</p>
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink to="/reception_car_type" className="nav-link">
                  <i className="nav-icon fas fa-calendar-plus"></i>
                  <p>予約受付</p>
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink to="/confirmation_list" className="nav-link">
                  <i className="nav-icon fas fa-calendar-alt"></i>
                  <p>予約確認</p>
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  to="/pdf/jaspa_okinawa_yoyaku_manual_v2.pdf"
                  target="_blank"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-book-open"></i>
                  <p>使い方マニュアル</p>
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    );
  }
}

export default Sidebar;
